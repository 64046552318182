import moment from 'moment';
import i18n from '../../../i18n';

export function getEventDateString(startTimeString: string | null, endTimeString: string | null, isFullDay = false) {
  const startTime = moment(startTimeString);
  const endTime = endTimeString && moment(endTimeString);
  const now = moment();

  // Different year
  if (!startTime.isSame(now, 'year')) {
    let result = startTime.format('ddd, MMM Do YYYY');

    if (endTime) {
      result += ` - ${endTime.format('MMM Do YYYY')}`;
    }

    return result;
  }

  // Multiple days
  if (endTime && !startTime.isSame(endTime, 'day')) {
    if (isFullDay) {
      return `${startTime.format('ddd, MMM Do')} - ${endTime.format('MMM Do')}`;
    }

    return `${startTime.format('ddd, MMM Do HH:mm')} - ${endTime.format('MMM Do HH:mm')}`;
  }

  // Single day
  let result = startTime.calendar(moment(), {
    sameDay: `[${i18n.t('dates:today')}]`,
    nextDay: `[${i18n.t('dates:tomorrow')}]`,
    lastDay: `[${i18n.t('dates:yesterday')}]`,
    lastWeek: 'ddd, MMM Do',
    nextWeek: 'ddd, MMM Do',
    sameElse: 'ddd, MMM Do',
  });

  // If not a full day event append start time
  if (!isFullDay) {
    result += ` ${i18n.t('dates:at')} ${startTime.format('HH:mm')}`;
  }

  // If not a full day event and there there is an end date append end time
  if (!isFullDay && endTime) {
    result += ` - ${endTime.format('HH:mm')}`;
  }

  return result;
}
