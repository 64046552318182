import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncTable } from '@common/components/table';
import ProfileImage from '@common/components/profile-image';
import { SearchBar } from '@common/components/search-bar';
import { useThunkDispatch } from '@common/hooks';
import mergeNames from '@utils/merged-names-text';
import { fetchGuests } from '../../../actions';
import { GuestListModes } from '../../../definitions';

import type { SimpleUser } from '@common/types/objects';
import type { HookStateSetter } from '@common/types/util-types';
import type { GuestCounts, Event } from '../../../types';

type GuestListProps = {
  mode: GuestListModes;
  event: Event;
  setCounts: HookStateSetter<GuestCounts>;
};

const maxShownItems = 1;

export const GuestList = ({ mode, event, setCounts }: GuestListProps) => {
  const [searchTerm, setSearchTerm] = useState<string | null>('');
  const thunkDispatch = useThunkDispatch();
  const { t } = useTranslation();

  const handleFetch = async (nextCursor: string, filters: { searchTerm: string; filter: string; }) => {
    const guestList = await thunkDispatch(fetchGuests(event.id, nextCursor, filters));

    setCounts(guestList.meta.counts);

    return guestList;
  };

  const columns = [
    { size: 0 },
    { className: 'Table__Cell__Title' },
    {},
    {},
  ];

  return (
    <div className="GuestList">
      <SearchBar
        className="GuestList__SearchBar"
        onSearch={setSearchTerm}
        placeholder={t('social:find_colleagues_by_name')}
      />
      <AsyncTable
        hideHeader
        columns={columns}
        data={{
          onFetch: handleFetch,
          useCursor: true,
          filter: {
            filter: mode,
            searchTerm,
          },
        }}
        renderRow={({ item: user }: { item: SimpleUser }) => [
          <ProfileImage user={user} />,
          user.full_name,
          // @ts-expect-error
          mergeNames(user.networks, maxShownItems, 'name', undefined, '+'),
          // @ts-expect-error
          mergeNames(user.functions, maxShownItems, 'name', undefined, '+'),
        ]}
        // @ts-expect-error
        scrollContainer={document.querySelector<HTMLDivElement>('.GuestListModal .modal-body')}
      />
    </div>
  );
};
