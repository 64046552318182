import React, { useState } from 'react';
import { t } from 'i18next';
import { useHistory } from 'react-router';

import Modal from '@common/components/modal';
import { Button } from '@common/components/button';
import { RadioButton } from '@common/components/radio-button';

import { useThunkDispatch } from '@common/hooks';
import { cancelEvent, deleteEvent } from '../../../../actions';
import type { Event } from '../../../../types';

export enum Modes {
  CANCEL = 'CANCEL',
  DELETE = 'DELETE',
}

type TerminateEventModalContentProps = {
  currentValue: Modes;
  setValue: (newValue: Modes) => void;
};

export const TerminateEventModalContent = ({ currentValue, setValue }: TerminateEventModalContentProps) => (
  <div className="TerminateEventModal__Content">
    <div className="TerminateEventModal__Option">
      <div className="TerminateEventModal__OptionText">
        <span className="TerminateEventModal__OptionTitle">{t('events:cancel')}</span>
        <span className="TerminateEventModal__OptionDescription">{t('events:cancel_desc')}</span>
      </div>

      <RadioButton
        className="TerminateEventModal__RadioButton"
        value={Modes.CANCEL}
        currentValue={currentValue}
        onChange={(value) => setValue(value as Modes)}
      />
    </div>

    <div className="TerminateEventModal__Option">
      <div className="TerminateEventModal__OptionText">
        <span className="TerminateEventModal__OptionTitle">{t('events:delete')}</span>
        <span className="TerminateEventModal__OptionDescription">{t('events:delete_desc')}</span>
      </div>

      <RadioButton
        className="TerminateEventModal__RadioButton"
        value={Modes.DELETE}
        currentValue={currentValue}
        onChange={(value) => setValue(value as Modes)}
      />
    </div>
  </div>
);

type TerminateEventModalFooterProps = {
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
};

export const TerminateEventModalFooter = ({ onClose, onConfirm, isLoading }: TerminateEventModalFooterProps) => (
  <div className="TerminateEventModal__Footer">
    <Button onClick={onClose}>
      { t('common:close') }
    </Button>
    <Button type="primary" onClick={onConfirm} isLoading={isLoading}>
      { t('core:confirm') }
    </Button>
  </div>
);

type TerminateEventModalProps = {
  eventsListPath?: string;
  children: JSX.Element;
  initialType: Modes;
  event: Event;
};

export const TerminateEventModal = ({ initialType, eventsListPath, event, children }: TerminateEventModalProps) => {
  const [mode, setMode] = useState<Modes>(initialType);
  const [isLoading, setIsLoading] = useState(false);
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();

  const handleConfirm = (handleHide: () => void) => async () => {
    setIsLoading(true);

    switch (mode) {
      case 'CANCEL': {
        await thunkDispatch(cancelEvent(event.id));
        break;
      }
      case 'DELETE': {
        await thunkDispatch(deleteEvent(event.id));
        break;
      }
      default:
        break;
    }

    setIsLoading(false);
    handleHide();
    if (eventsListPath) history.push(eventsListPath);
  };

  return (
    <Modal
      hideDefaultClose
      className="TerminateEventModal"
      title={t('events:cancel_or_delete')}
      content={<TerminateEventModalContent currentValue={mode} setValue={setMode} />}
      footer={(handleHide) => (
        <TerminateEventModalFooter
          onClose={handleHide}
          onConfirm={handleConfirm(handleHide)}
          isLoading={isLoading}
        />
      )}
    >
      {children}
    </Modal>
  );
};
