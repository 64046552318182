import moment from 'moment';
import { Api } from '../../services/api';

const formatDate = (date: string | ReturnType<typeof moment>) => moment(date).utc().format('YYYYMMDD[T]HHmmss[Z]');

type EventSettings = {
  name: string;
  details?: string | null;
  startDate: string;
  endDate?: string | null;
  location?: string | null;
};

export const getAddCalendarEventUrl = (settings: EventSettings) => {
  const {
    name,
    details,
    startDate,
    endDate,
    location,
  } = settings;

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = endDate ? formatDate(endDate) : formatDate(moment(startDate).add(1, 'day'));

  const query = Api.utils.toQuery({
    details,
    location,
    text: name,
    dates: `${formattedStartDate}/${formattedEndDate}`,
    ctz: 'Etc/UTC',
  });

  return `https://calendar.google.com/calendar/r/eventedit?${query}`;
};
