import { ESearchPredicateTypes } from '@common/definitions';
import type { SimpleUser, PlaceOnMap, Predicate } from '@common/types/objects';
import type { Nullable } from '@common/types/util-types';

export enum EventStatus {
  UPCOMING = 'UPCOMING',
  DRAFT = 'DRAFT',
  CANCELED = 'CANCELED',
  PAST = 'PAST',
  LIVE = 'LIVE',
}

export type EventAudience = {
  id: string;
  created_at: string;
  deleted_at: string | null;
  updated_at: string | null;
  is_active: boolean;
  name: string;
  organisation_id: string;
  parent_type: string;
  type: string;
  predicate_type: ESearchPredicateTypes;
  predicates: Predicate[];
};

export interface EventPayload {
  name: string;
  audience: EventAudience | null;
  header_image_url?: string | null;
  is_header_image_remote_file?: boolean;
  status?: string;
  start_time: string | Date;
  end_time?: string | Date;
  is_full_day?: boolean;
  description?: string;
  web_link?: string;
  location?: Partial<Nullable<PlaceOnMap>>;
  settings: {
    max_guest_limit: number | null;
    only_admins_can_post: boolean;
    hide_guest_list: boolean;
    registration_deadline: string | null;
    send_event_created_notification: boolean;
  };
}

export interface EventSimpleDto {
  id: string;
  organisation_id: string;
  invite_type: 'direct' | 'ongoing';
  name: string;
  is_header_image_remote_file: boolean;
  status: string;
  start_time: string | null;
  end_time: string | null;
  header_image_url: string;
  is_full_day: boolean;
  description: string | null;
  location: Partial<Nullable<PlaceOnMap>>;
  web_link: string | null;
  settings: {
    disable_guest_list: boolean;
    max_guest_limit: number | null;
    only_admins_can_post: boolean;
    hide_guest_list: boolean;
    registration_deadline: string | null;
    send_event_created_notification: boolean;
    send_event_availability_reminder_notification: boolean;
    send_event_start_reminder: number[];
  };
  created_by: SimpleUser | null;
  created_at: string;
  updated_at: string;
  canceled_at: string | null;
}

// If you are changing this type you also have to update ./reducer.ts:eventAdminScopeKeys
export type EventAdminScopeFragment = {
  audience: EventAudience | null;
  going_users: {
    count: number;
    users_preview: Array<SimpleUser> | [];
  } | null;
};

export type EventForAdminDto = EventSimpleDto & EventAdminScopeFragment;

// If you are changing this type you also have to update ./reducer.ts:eventUserScopeKeys
export type EventUserScopeFragment = Omit<EventAdminScopeFragment, 'audience'> & {
  invited_users_count: number | null;
  remaining_spots_count: number | null;
  is_going: boolean | null;
  registration_deadline: string | Date | null;
};

export type EventForUserDto = EventSimpleDto & EventUserScopeFragment;

export type Event = EventForAdminDto | EventForUserDto;

export interface GuestCounts {
  going: number;
  not_going: number;
}

export interface ListGuestsParams {
  eventId: string;
  userId: string;
  organisationId: string;
}

export interface ExportGuestListDto {
  'Employee name': string;
  Communities: string;
  Status: string;
}
