import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileImage } from '@common/components/profile-image';
import { Balloon, BalloonTitle } from '@common/components/balloon';
import type { SimpleUser } from '@common/types/objects';
import List from '@common/components/list';
import Api from '@common/services/api';
import ConfirmButton from '@common/components/confirm-button';
import { Button } from '@common/components/button';
import { useThunkDispatch } from '@hooks/redux';
import * as Alert from '../../../../common/services/alert';
import { GuestListModal } from '../guest-list-modal';
import { fetchGuests } from '../../actions';
import { GuestListModes } from '../../definitions';

import type { Event } from '../../types';
import Permission from '@common/components/permission';
import { EPermissions } from '@common/definitions';


type InviteesWidgetProps = {
  mode: GuestListModes;
  event: Event;
};

export const InviteesWidget = ({ mode, event }: InviteesWidgetProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const [invitees, setInvitees] = useState<SimpleUser[]>([]);
  const [inviteesCount, setInviteesCount] = useState<number>();

  useEffect(() => {
    (async () => {
      const { data, meta } = await thunkDispatch(fetchGuests(event.id, undefined, { filter: mode }, 8));
      setInvitees(data);
      // @ts-expect-error
      setInviteesCount(meta.counts[mode.toLowerCase()]);
    })();
  // @ts-expect-error
  }, [mode, event.is_going]);

  const translationForMode = {
    [GuestListModes.GOING]: t('social:going_to_event'),
    [GuestListModes.NOT_GOING]: t('social:not_going_to_event'),
    [GuestListModes.NOT_RESPONDED]: t('social:not_responded_to_event'),
  };

  const onConfirm = useCallback(async () => {
    const request = await Api.post(`/v1/organisations/${event.organisation_id}/events/${event.id}/reminders/attendance`);
    if (request && request.success) {
      Alert.success(t('social:event_reminder_success'));
    }
  }, [event]);

  return (
    <Balloon>
      <BalloonTitle
        showMore={(
          <GuestListModal initialMode={mode} event={event}>
            <a>{t('social:show_all_invitees')}</a>
          </GuestListModal>
        )}
      >
        {translationForMode[mode]}
        <small>{inviteesCount}</small>
      </BalloonTitle>

      <List
        containerClassName="InviteesWidget__ProfilePictures"
        items={invitees}
        renderRow={ProfileImage}
        rowProps={(item: SimpleUser) => ({
          size: 24,
          enablePopover: true,
          user: item,
          className: 'InviteesWidget__ProfilePicture',
        })}
        placeholder={t('social:no_invitees', { context: mode.toLowerCase() })}
      />

      <Permission name={EPermissions.ORGANISATION_EVENTS_CREATE}>
        {
          mode === GuestListModes.NOT_RESPONDED && !!inviteesCount &&
          (
            <div className="InviteesWidget__RemindButton">
              <ConfirmButton
                title={inviteesCount === 1 ?
                  t('social:event_reminder_confirm', { count: inviteesCount }) :
                  t('social:event_reminder_confirm_plural', { count: inviteesCount })}
                onConfirm={onConfirm}
              >
                <Button>
                  {t('social:event_reminder_button')}
                </Button>
              </ConfirmButton>
            </div>
          )
        }
      </Permission>
    </Balloon>
  );
};
