import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../common/components/modal';
import { GuestListModes } from '../../definitions';
import { GuestList } from './guest-list';

import type { Event, GuestCounts } from '../../types';

type GuestListProps = {
  initialMode: GuestListModes;
  children: JSX.Element;
  event: Event;
};

type GuestListModalTab = {
  value: GuestListModes;
  title: string;
  count: number;
};

export const GuestListModal = ({ initialMode, children, event }: GuestListProps) => {
  const [counts, setCounts] = useState<GuestCounts>({ going: 0, not_going: 0 });
  const [tab, setTab] = useState<string>(initialMode);
  const { t } = useTranslation();

  const tabs: GuestListModalTab[] = [{
    value: GuestListModes.GOING,
    title: t('social:going_to_event'),
    count: counts.going,
  }, {
    value: GuestListModes.NOT_GOING,
    title: t('social:not_going_to_event'),
    count: counts.not_going,
  }, {
    value: GuestListModes.NOT_RESPONDED,
    title: t('social:not_responded_to_event'),
    // @ts-expect-error
    count: counts.not_responded,
  }];

  return (
    <Modal
      size="large"
      title={t('social:guest_list')}
      onClose={() => (initialMode !== tab ? setTab(initialMode) : undefined)}
      header={(
        <Modal.TabHeader
          className="GuestListModal__Tabs"
          onChange={setTab}
          active={tab}
          options={tabs}
        />
      )}
      content={(
        <GuestList
          mode={tab as GuestListModes}
          event={event}
          setCounts={setCounts}
        />
      )}
    >
      {children}
    </Modal>
  );
};
