import * as React from 'react';
import { Trans } from 'react-i18next';

import ProfileImage from '@common/components/profile-image';
import { EventItemComponent } from './event-item';
import type { EventForAdminDto } from '../../types';

type Props = {
  item: EventForAdminDto;
  onClick: (item: EventForAdminDto) => unknown;
  onEdit: (item: EventForAdminDto) => unknown;
  onDuplicate: (item: EventForAdminDto) => unknown;
};

export const EventItemAdminComponent = ({ item, onClick, onEdit, onDuplicate }: Props) => (
  <EventItemComponent
    item={item}
    maxGuestLimit={item.settings?.max_guest_limit}
    onClick={() => onClick(item)}
    onEdit={() => onEdit(item)}
    // @ts-expect-error
    onDuplicate={onDuplicate}
  >
    <div className="EventItem__Creator">
      <ProfileImage size={24} user={item.created_by!} />
      <span>
        <Trans
          i18nKey="events:created_by"
          values={{ name: item.created_by?.full_name }}
          components={[<b />]}
        />
      </span>
    </div>
  </EventItemComponent>
);
