import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Dropdown from '@common/components/dropdown';
import Icon from '@common/components/icon';
import Permission from '@common/components/permission';
import { downloadURL } from '@utils/file';
import { EPermissions } from '@common/definitions';
import { useThunkDispatch } from '@common/hooks';
import { Modes, TerminateEventModal } from './terminate-event-modal';
import { fetchGuestsListCSV } from '../../../actions';
import { EventStatus } from '../../../types';

import type { Event } from '../../../types';
import { EEventStatus } from '../../../definitions';
import { Button } from '@common/components/button';

type EventItemMoreDropdownProps = {
  eventsListPath?: string;
  event: Event;
  onEdit?: () => unknown;
  onDuplicate?: () => unknown;
};

export const EventItemMoreDropdown = ({
  event,
  eventsListPath,
  onEdit,
  onDuplicate
}: EventItemMoreDropdownProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();

  const handleGetGuestsList = async () => {
    const guestList = await thunkDispatch(fetchGuestsListCSV(event.id));
    downloadURL(URL.createObjectURL(guestList), `${event.name}.csv`);
  };

  const now = moment();
  const isInPast = moment(event.start_time).isBefore(now);
  const canEdit = (
    event.status === EventStatus.DRAFT
    || (event.status === EventStatus.LIVE && !isInPast)
  );

  return (
    <Dropdown
      alignRight
      className="EventItemDropdown"
      toggle={eventsListPath ? (
        <Button icon="more_vert" />
      ) : (
        <div className="EventItemDropdownToggle">
          <Icon type="more_vert" />
        </div>
      )}
    >
      {onEdit && (
        <Permission name={EPermissions.ORGANISATION_EVENTS_UPDATE} and={canEdit}>
          <Dropdown.Item onClick={onEdit}>
            <Icon type="edit" />
            <span>{t('events:edit')}</span>
          </Dropdown.Item>
        </Permission>
      )}

      {onDuplicate && (
        <Permission name={EPermissions.ORGANISATION_EVENTS_UPDATE}>
          <Dropdown.Item onClick={onDuplicate}>
            <Icon type="content_copy" />
            <span>{t('events:duplicate')}</span>
          </Dropdown.Item>
        </Permission>
      )}

      {event.status === EEventStatus.LIVE && (
        <Dropdown.Item onClick={handleGetGuestsList}>
          <Icon type="download" />
          <span>{t('events:export_guests')}</span>
        </Dropdown.Item>
      )}

      {event.status === EEventStatus.LIVE && !isInPast && (
        <Permission name={EPermissions.ORGANISATION_EVENTS_REMOVE}>
          <TerminateEventModal
            initialType={Modes.CANCEL}
            event={event}
            eventsListPath={eventsListPath}
          >
            <Dropdown.Item>
              <Icon type="close" />
              <span>{t('events:cancel')}</span>
            </Dropdown.Item>
          </TerminateEventModal>
        </Permission>
      )}

      <Permission name={EPermissions.ORGANISATION_EVENTS_REMOVE}>
        <TerminateEventModal
          initialType={Modes.DELETE}
          event={event}
          eventsListPath={eventsListPath}
        >
          <Dropdown.Item danger>
            <Icon type="delete" />
            <span>{t('events:delete')}</span>
          </Dropdown.Item>
        </TerminateEventModal>
      </Permission>
    </Dropdown>
  );
};
