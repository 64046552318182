import React, { ReactNode, useMemo } from 'react';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';

import Icon from '../../../../common/components/icon';
import ProfileImage from '@common/components/profile-image';
import { EventItemMoreDropdown } from './event-item-more-dropdown';
import { getEventDateString } from '../../utils/date';
import { EEventStatus } from '../../definitions';
import type { Event } from '../../types';

type Props = {
  children: ReactNode,
  item: Event;
  maxGuestLimit?: number | null,
  onClick: () => unknown;
  onEdit: () => unknown;
  onDuplicate?: () => unknown;
};

export const EventItemComponent = ({
  children, item, maxGuestLimit, onClick, onEdit, onDuplicate
}: Props) => {
  const { t } = useTranslation();

  const daysTillEvent = moment(item.start_time).diff(moment(), 'day');

  const guestList = useMemo(() => {
    if (item.going_users && item.going_users.count > 0) {
      return (
        <>
          <div className="EventItem__UsersPreview">
            {item.going_users.users_preview.map((user) => (
              <ProfileImage key={user.id} size={24} user={user} />
            ))}
          </div>
          {daysTillEvent >= 0 ? (
            <>
              <Trans i18nKey="events:going_count" values={{ count: item.going_users.count }} />
              {maxGuestLimit && maxGuestLimit > 0 && (
                <span>{` . ${t('events:spots_left', { count: maxGuestLimit - item.going_users.count })}`}</span>
              )}
            </>
          ) : (
            <Trans i18nKey="events:attended_count" values={{ count: item.going_users.count }} />
          )}
        </>
      );
    }
    return (
      <span><Trans i18nKey="events:no_attendees" /></span>
    );
  }, [daysTillEvent, item, maxGuestLimit]);

  return (
    <div className="EventItem" onClick={onClick} role="listitem">
      <div
        className="EventItem__Image"
        style={{ backgroundImage: `url(${item.header_image_url || '/static/images/event-placeholder.png'})` }}
      >
        <EventItemMoreDropdown
          event={item}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
        />
      </div>
      {item.status === EEventStatus.CANCELED && (
        <div className="EventItem__CancelChip">
          <Icon type="event_busy__filled" />
          {t('events:canceled')}
        </div>
      )}
      <div className="EventItem__Info">
        <small className="EventItem__date">
          {getEventDateString(item.start_time, item.end_time, item.is_full_day)}
        </small>
        <h3>
          {item.name}
        </h3>
        {item.web_link && (
          <a className="EventItem__Link" href={item.web_link} target="_blank" rel="noopener noreferrer">
            <Icon type="insert_link" />
            <Trans i18nKey="events:visit_website" />
          </a>
        )}
        {!item.web_link && item.location?.name && (
          <div className="EventItem__Location">
            <Icon type="place__filled" />
            <span>{item.location.name}</span>
          </div>
        )}
      </div>
      <div className="EventItem__Participants">
        {!item.settings.disable_guest_list && (
          <>
            {guestList}
          </>
        )}
      </div>
      {children}
    </div>
  );
};
