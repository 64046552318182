import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar/top-navigation-bar';
import { Button } from '@common/components/button';
import { EventItemMoreDropdown } from '../event-item/event-item-more-dropdown';

import { useEncodedFullPath } from '@common/hooks/url';
import type { EventForUserDto } from '../../types';

type EventDetailsHeaderProps = {
  event: EventForUserDto;
  eventsListPath: string;
};

export const EventDetailsHeader = ({ event, eventsListPath }: EventDetailsHeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const encodedPath = useEncodedFullPath();

  return (
    <TopNavigationBar
      className="EventDetailsHeader"
      title={event.name}
      breadcrumbs={[
        { name: t('core:tab_events'), path: eventsListPath },
        { name: event.name },
      ]}
      action={(
        <>
          <Button className="EventDetailsHeader__BackButton" onClick={() => history.push(eventsListPath)}>
            {t('events:back_from_details')}
          </Button>
          <EventItemMoreDropdown
            event={event}
            eventsListPath={eventsListPath}
            onEdit={() => history.push(`/admin/events/edit/${event.id}?back=${encodedPath}`)}
          />
        </>
      )}
    />
  );
};
