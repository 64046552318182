import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Button } from '../../../../../common/components/button';
import Dropdown from '../../../../../common/components/dropdown';
import Confirm from '../../../../../common/components/confirm-button';
import { absentEvent, attendEvent } from '../../../actions';
import { isActionDisabledForAttendance } from '../../../utils';
import { useRegenerativeState } from '../../../../../common/hooks/use-regenerative-state';
import { useThunkDispatch } from '../../../../../common/hooks';

import type { EventForUserDto } from '../../../types';

type AttendButtonProps = {
  event: EventForUserDto;
};

export const AttendButtons = ({ event }: AttendButtonProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const [isGoing, setIsGoing] = useRegenerativeState<boolean | null | undefined>(event.is_going);

  const handleSetGoingStatus = (isUserGoing: boolean) => async () => {
    await (isUserGoing
      ? thunkDispatch(attendEvent(event.id))
      : thunkDispatch(absentEvent(event.id)));

    setIsGoing(isUserGoing);
  };

  const isDisabled = useMemo(() => isActionDisabledForAttendance(event), [event]);

  if (isDisabled) return null;

  switch (isGoing) {
    case true: {
      const toggle = (
        <Button
          type="success"
          icon="check_circle"
          className="AttendButtons__Button"
          disabled={isDisabled}
        >
          <Trans i18nKey="events:going" />
        </Button>
      );

      return (
        <div className="AttendButtons">
          <span>
            <Trans i18nKey="events:you_are_going" />
          </span>
          <Dropdown toggle={toggle} disabled={isDisabled}>
            <Confirm
              title={t('events:confirm_not_going_to_event')}
              onConfirm={handleSetGoingStatus(false)}
            >
              <Dropdown.Item>
                <Trans i18nKey="events:not_going" />
              </Dropdown.Item>
            </Confirm>
          </Dropdown>
        </div>
      );
    }

    case false: {
      const toggle = (
        <Button
          type="warning"
          icon="close"
          className="AttendButtons__Button"
          disabled={isDisabled}
        >
          <Trans i18nKey="events:not_going" />
        </Button>
      );

      return (
        <div className="AttendButtons">
          <span>
            <Trans i18nKey="events:you_are_not_going" />
          </span>
          <Dropdown toggle={toggle} disabled={isDisabled}>
            <Dropdown.Item onClick={handleSetGoingStatus(true)}>
              <Trans i18nKey="events:going" />
            </Dropdown.Item>
          </Dropdown>
        </div>
      );
    }

    case null:
    default:
      return (
        <div className="AttendButtons">
          <span>
            <Trans i18nKey="events:you_got_invited" />
          </span>
          <Button
            className="AttendButtons__Button"
            icon="check_circle"
            onClick={handleSetGoingStatus(true)}
            disabled={isDisabled}
          >
            <Trans i18nKey="events:going" />
          </Button>
          <Button
            className="AttendButtons__Button"
            icon="close"
            onClick={handleSetGoingStatus(false)}
            disabled={isDisabled}
          >
            <Trans i18nKey="events:not_going" />
          </Button>
        </div>
      );
  }
};
