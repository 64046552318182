export const EEventStatus = <const>{
  UPCOMING: 'UPCOMING',
  DRAFT: 'DRAFT',
  CANCELED: 'CANCELED',
  PAST: 'PAST',
  LIVE: 'LIVE',
};

export const EEventType = <const>{
  DIRECT: 'direct',
  ONGOING: 'ongoing'
};

export enum GuestListModes {
  GOING = 'GOING',
  NOT_GOING = 'NOT_GOING',
  NOT_RESPONDED = 'NOT_RESPONDED',
}
