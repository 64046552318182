import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@common/components/card';
import Text from '@common/components/text';
import { Icon } from '@common/components/icon';
import { AttendButtons } from './attend-buttons';

import { getEventDateString } from '../../utils/date';
import { getAddCalendarEventUrl, getMapsURL } from '@common/utils/google';
import { EEventStatus } from '../../definitions';
import type { EventForUserDto } from '../../types';

type EventDetailsHeaderProps = {
  event: EventForUserDto;
  isAdmin: boolean; // whether the current user is an admin or not
};

export const EventDetailsCard = ({ event, isAdmin }: EventDetailsHeaderProps) => {
  const { t } = useTranslation();

  const {
    name,
    status,
    description,
    location,
    start_time,
    end_time,
    web_link,
    header_image_url,
  } = event;

  const addCalendarEventUrl = getAddCalendarEventUrl({
    name: event.name,
    startDate: start_time || '',
    endDate: end_time,
    details: description,
    location: location?.address,
  });

  return (
    <Card containerClassName="EventDetailsCard">
      <img
        className="EventDetailsCard__HeaderImage"
        alt="HeaderEventImage"
        src={header_image_url || '/static/images/event-placeholder.png'}
      />

      <div className="EventDetailsCard__Content">
        <div className="EventDetailsCard__Details">
          <span className="EventDetailsCard__Name">
            {name}
            {status === EEventStatus.DRAFT && (
              <div className="EventDetailsCard__Badge">{t('events:filter_draft')}</div>
            )}
          </span>

          <div className="EventDetailsCard__Detail">
            <Icon type="calendar_today" />
            <span>{getEventDateString(event.start_time, event.end_time, event.is_full_day)}</span>
            {' · '}
            <a href={addCalendarEventUrl} target="_blank" rel="noopener noreferrer">{t('events:add_to_calendar')}</a>
          </div>

          {web_link && (
            <div className="EventDetailsCard__Detail webLink">
              <Icon type="insert_link" />
              <a href={web_link} target="_blank" rel="noopener noreferrer">{web_link}</a>
            </div>
          )}

          {location?.geometry && (
            <div className="EventDetailsCard__Detail EventDetailsCard__Detail--location">
              <Icon type="place" />
              <div className="EventDetailsCard__LocationDetailText">
                <div>
                  <span>{`${location.name || `${location.geometry.lat}, ${location.geometry.lng}`} · `}</span>
                  <a
                    href={getMapsURL(location.geometry.lat, location.geometry.lng)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('events:open_in_google_maps')}
                  </a>
                </div>
                <span>{location.address}</span>
              </div>
            </div>
          )}
        </div>
        <Text className="EventDetailsCard__Description">{description}</Text>
        {
          !isAdmin && // deactivated for admins as temporary patch for PD-8209
          status === EEventStatus.LIVE &&
          !event.settings.disable_guest_list && (
            <AttendButtons event={event} />
          )
        }
      </div>
    </Card>
  );
};
