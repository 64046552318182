import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Placeholder from '@common/components/placeholder';
import Permission from '@common/components/permission';
import { EPermissions } from '@common/definitions';
import { Button } from '@common/components/button';

export const EventsPlaceholder = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="EventsPlaceholderWrapper">
      <Placeholder
        image="/static/images/placeholders/files.svg"
        title={t('events:no_scheduled_events')}
        description={t('events:bring_your_team_together')}
        action={(
          <Permission name={EPermissions.ORGANISATION_EVENTS_CREATE}>
            <Button type="primary" iconRight="add" onClick={() => history.push('/admin/events/create')}>
              {t('events:create_event')}
            </Button>
          </Permission>
        )}
      />
    </div>
  );
};
