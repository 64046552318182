import React, { useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useThunkDispatch } from '@hooks/redux';

import Container from '@common/components/container';
import Placeholder from '@common/components/placeholder';
import Spinner from '@common/components/spinner';
import Feed from '../../../social/components/feed';
import PostMessage from '../../../social/components/post-message';
import { EventDetailsHeader } from '../../components/event-details-header/event-details-header';
import { EventDetailsCard } from '../../components/event-details-card';
import { InviteesWidget } from '../../components/invitees-widget/invitees-widget';

import * as userSelector from '../../../core/selectors/logged-user';
import * as timelineSelector from '../../../social/selectors/timeline';
import * as eventsSelector from '../../selector';
import fetchTimeline, { receiveMessages } from '../../../social/actions/fetch-timeline';
import { fetchMyEvent } from '../../actions';

import { GuestListModes } from '../../definitions';
import type { FeedItem } from '../../../social/types/objects';
import type { StoreState } from '@common/types/store';
import { useQueryParam } from '@common/hooks/url';

type OnPostRequest = { data: Record<string, unknown>[]; meta: Record<string, unknown>; };

type OwnProps = RouteComponentProps<{ networkId: string, eventId: string }>;

const mapStateToProps = (state: StoreState, props: OwnProps) => {
  return {
    loggedUser: userSelector.selected(state),
    event: eventsSelector.getUserScopeEvent(state, props.match.params.eventId),
    // TODO: remove type assert when selector will be fixed
    feed: timelineSelector.feed(state) as FeedItem[],
    isAdmin: userSelector.isAdmin(state)
  };
};

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

export const UnwrappedEventDetailsContainer = ({
  loggedUser, event, feed, isAdmin
}: Props) => {
  const thunkDispatch = useThunkDispatch();
  const { t } = useTranslation();
  const { eventId, networkId } = useParams<{ networkId?: string, eventId: string }>();

  useEffect(() => {
    if (!event) thunkDispatch(fetchMyEvent(eventId));
  }, [eventId, networkId]);

  const back = useQueryParam('back');

  if (!event) return <Spinner centered />;

  return (
    <Container name="Event">
      <EventDetailsHeader
        event={event}
        eventsListPath={(
          back ||
          (networkId ? `/networks/${networkId}/events` : '/admin/events')
        )}
      />

      <Container.Content>
        <EventDetailsCard event={event} isAdmin={isAdmin} />
      </Container.Content>

      <Container.Content horizontal>
        <Container.Column>
          {/* @ts-expect-error */}
          <PostMessage
            disableHeader
            loggedUser={loggedUser}
            channel={{
              type: 'event',
              id: event.id,
              parent: event,
            }}
            allowSchedule={false}
          />
          <Feed
            items={feed}
            data={{
              dispatch: thunkDispatch,
              useCursor: true,
              onFetch: fetchTimeline,
              filter: {
                type: 'event',
                id: eventId,
              },
              onPostFetch: (request: OnPostRequest) => thunkDispatch(receiveMessages(request)),
            }}
            placeholder={(
              <Placeholder
                size="small"
                title={t('social:feed_placeholder_title')}
                description={t('social:feed_placeholder_description')}
                image="/static/images/placeholders/timeline.svg"
              />
            )}
          />
        </Container.Column>

        {!event.settings.disable_guest_list && (
          <Container.SideBar size="large">
            <InviteesWidget mode={GuestListModes.GOING} event={event} />
            <InviteesWidget mode={GuestListModes.NOT_GOING} event={event} />
            <InviteesWidget mode={GuestListModes.NOT_RESPONDED} event={event} />
          </Container.SideBar>
        )}
      </Container.Content>
    </Container>
  );
};

export const EventDetailsContainer = connector(UnwrappedEventDetailsContainer);
