import React from 'react';

type Props = {
  children: React.ReactNode,
};

export const Balloon = ({ children }: Props) => (
  <div className="balloon">
    {children}
  </div>
);

type TitleProps = {
  children: React.ReactNode,
  showMore?: React.ReactNode,
};

export const BalloonTitle = ({ children, showMore }: TitleProps) => (
  <h5 className="balloon__title">
    <span>{children}</span>
    {showMore && (
      <div className="pull-right">
        {showMore}
      </div>
    )}
  </h5>
);
