import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Confirm from '../../../../common/components/confirm-button';
import Dropdown from '../../../../common/components/dropdown';
import { Button } from '../../../../common/components/button';
import { EventItemComponent } from './event-item';
import { isActionDisabledForAttendance } from '../../utils';
import * as EventActions from '../../actions';
import { useThunkDispatch } from '../../../../common/hooks';
import type { EventForUserDto } from '../../types';

type Props = {
  item: EventForUserDto;
  onClick: (item: EventForUserDto) => unknown;
  onEdit: (item: EventForUserDto) => unknown;
};

const getActionButton = (item: EventForUserDto) => {
  switch (item.is_going) {
    case null:
      return <Button size="default" icon="help"><Trans i18nKey="events:are_you_going" /></Button>;
    case true:
      return <Button size="default" type="success" icon="check_circle"><Trans i18nKey="events:going" /></Button>;
    case false:
      return <Button size="default" type="warning" icon="close"><Trans i18nKey="events:not_going" /></Button>;
    default:
      return null;
  }
};

export const EventItemEmployeeComponent = ({ item, onClick, onEdit }: Props) => {
  const thunkDispatch = useThunkDispatch();
  const { t } = useTranslation();

  return (
    <EventItemComponent
      item={item}
      onClick={() => onClick(item)}
      onEdit={() => onEdit(item)}
    >
      {!item.settings.disable_guest_list && (
        <div className="EventItem__Button">
          <Dropdown
            alignRight
            toggle={getActionButton(item)}
            disabled={isActionDisabledForAttendance(item)}
          >
            {item.is_going !== true && (
              <Dropdown.Item
                icon="check_circle"
                onClick={() => thunkDispatch(EventActions.attendEvent(item.id))}
              >
                <Trans i18nKey="social:going_to_event" />
              </Dropdown.Item>
            )}
            {item.is_going !== false && (
              <Confirm
                title={t('events:confirm_not_going_to_event')}
                onConfirm={() => void thunkDispatch(EventActions.absentEvent(item.id))}
              >
                <Dropdown.Item icon="close">
                  <Trans i18nKey="social:not_going_to_event" />
                </Dropdown.Item>
              </Confirm>
            )}
          </Dropdown>
        </div>
      )}
    </EventItemComponent>
  );
};
