import { useState, useEffect } from 'react';

export const useRegenerativeState = <T = undefined>(value: T): [T, (val: T) => void] => {
  const [state, setState] = useState<T>(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return [state, setState];
};
