import moment from 'moment';

import { EventStatus } from '../types';

import type { Event, EventForUserDto } from '../types';

// When status is not (draft or live and in the future)
export const isActionDisabled = (event: Event) => {
  const now = moment();

  return !(
    event.status === EventStatus.DRAFT
    || (event.status === EventStatus.LIVE && moment(event.start_time).isAfter(now))
  );
};

export const isActionDisabledForAttendance = (event: EventForUserDto) => {
  const now = moment();
  const deadline = event.registration_deadline && moment(event.registration_deadline);

  return isActionDisabled(event)
    || (deadline && deadline.isBefore(now))
    || (event.remaining_spots_count === 0);
};
